@import "dropdown-tree.css";

.react-dropdown-tree-select {
  /* @apply */
}

.react-dropdown-tree-select .dropdown {
  @apply appearance-none block w-full;
}

.react-dropdown-tree-select a.dropdown-trigger {
  @apply appearance-none block w-full px-3 py-1 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none;
}

.react-dropdown-tree-select a.dropdown-trigger.top::after {
  content: none !important;
}

.react-dropdown-tree-select a.dropdown-trigger.bottom::after {
  content: none !important;
}

.react-dropdown-tree-select ul.tag-list {
  @apply flex flex-wrap;
}

.react-dropdown-tree-select li.tag-item {
  @apply flex items-center;
}

.react-dropdown-tree-select li.tag-item:not(:last-child) {
  @apply flex items-center my-0.5 mx-1;
}

.react-dropdown-tree-select li.tag-item .tag {
  @apply flex items-center rounded-full py-0 px-2 text-gray-500 bg-gray-200 font-semibold text-sm;
}

.react-dropdown-tree-select li.tag-item button {
  @apply text-lg pl-1 leading-none;
}

.react-dropdown-tree-select li.tag-item .placeholder {
  @apply flex items-center text-sm text-gray-500;
}

.react-dropdown-tree-select li.tag-item .placeholder::after {
  @apply text-sm;
}

.react-dropdown-tree-select a.dropdown-trigger.top .placeholder::after {
  @apply pl-1;

  content: "\25B2";
}

.react-dropdown-tree-select a.dropdown-trigger.bottom .placeholder::after {
  @apply pl-1;

  content: "\25BC";
}

.react-dropdown-tree-select input.search {
  @apply accent-red-50 px-2 py-1 sticky top-0;
}

.react-dropdown-tree-select div.dropdown-content {
  @apply w-full max-h-[300px] overflow-y-auto;

  padding: 0 !important;
}

.react-dropdown-tree-select div.dropdown-content .root {
  @apply p-2 !important;
}

.react-dropdown-tree-select li.node.tree {
  @apply flex items-center;
}

/*
Depth 0 Node 선택이 되지 않도록...
https://github.com/dowjones/react-dropdown-tree-select/issues/369#issuecomment-934456681
*/
.react-dropdown-tree-select li.node.disabled label {
  @apply w-full cursor-pointer text-black;
}

.react-dropdown-tree-select li.node.disabled input {
  @apply hidden;
}

.react-dropdown-tree-select li.node.disabled i.toggle {
  @apply cursor-pointer pr-2;
}

.react-dropdown-tree-select li.node.leaf {
  @apply flex items-center;
}

.react-dropdown-tree-select li.node.leaf label {
  @apply w-full;
}

.react-dropdown-tree-select i.toggle.collapsed::after {
  /* @apply */
}

.react-dropdown-tree-select i.toggle.expanded::after {
  /* @apply */
}

.react-dropdown-tree-select input.checkbox-item {
  @apply mt-1 mr-2 w-4 h-4 align-top bg-white checked:bg-blue-600 bg-center bg-no-repeat bg-contain rounded-sm border border-gray-300 checked:border-blue-600 focus:outline-none transition duration-200 appearance-none cursor-pointer form-check-input;
}
