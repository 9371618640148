.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle;
}

.expert-signup-treeview .dx-treeview-toggle-item-visibility {
  top: 5px;
}

.expert-signup-treeview .dx-treeview-item-with-checkbox .dx-checkbox {
  top: 10px;
}

.expert-signup-treeview .dx-treeview-node-container .dx-checkbox {
  visibility: hidden;
}

.expert-signup-treeview .dx-treeview-node-container .dx-treeview-item {
  padding: 10px 6px 10px 10px;
}

.expert-signup-treeview .dx-treeview-node-container-opened .dx-checkbox {
  visibility: visible;
}

.expert-signup-treeview .dx-treeview-node-container-opened .dx-treeview-item {
  padding: 10px 6px 10px 40px;
}

.dx-custom-alert {
  font-size: 70px;
  color: #f8bb86;
}

.dx-scrollview.side-bar {
  height: calc(100% - 4rem);
}

.dx-button.popup-button {
  padding: 8px 0;
  border-color: #28c76f;
  height: 34px;
  color: #28c76f;
}

.dx-datagrid-search-panel {
  margin: 0;
}

/* -------------------- 공통 -------------------- */

/* 토스트알림 배경색 */
.dx-toast-info {
  background-color: #28c76f;
}

.dx-toast-success {
  background-color: #28c76f;
}

/* dx-success-btn */
.dx-button-mode-contained.dx-button-success {
  background-color: #28c76f;
}

.file-button .dx-button-text {
  font-size: 0.75rem;
}

/* 로딩바 */
.dx-loadindicator-icon .dx-loadindicator-segment {
  background: #28c76f;
}

/* -------------------- 그리드 -------------------- */
@media not print {
  .dx-datagrid-header-panel .dx-toolbar .dx-toolbar-after .button-hide-mobile .dx-button-text {
    display: none;
  }

  .dx-datagrid-header-panel .dx-toolbar .dx-toolbar-after .button-hide-mobile .dx-icon {
    margin-right: 0;
  }

  .dx-datagrid-header-panel .dx-toolbar .dx-toolbar-after .button-hide-mobile .dx-button-content {
    padding: 8px;
  }

  .dx-datagrid-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-menu-container .dx-button {
    margin-left: 0;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-header-row {
    height: 40px;
    background-color: #f3f2f7;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-header-row td[role="columnheader"] {
    color: #333;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor-container {
    border: none;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-row > td {
    vertical-align: middle;
    padding: 6px;
  }

  .dx-datagrid-rowsview .dx-row.dx-master-detail-row > .dx-master-detail-cell {
    padding: 20px;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-row-lines > td {
    border-bottom: 1px solid #ebe9f1;
  }

  .dx-datagrid-content
    .dx-datagrid-table
    .dx-data-row:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
    > td:not(.dx-focused) {
    cursor: pointer;
  }

  .dx-datagrid-content .dx-datagrid-table .disabled-column {
    background-color: #f5f5f5;
  }

  .dx-widget.dx-datagrid-pager.dx-pager {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .dx-toolbar-menu-section .dx-item-content .dx-toolbar-item-auto-hide .dx-button-default {
    color: #333;
  }
}

/* 그리드 포커스 */
.dx-datagrid-filter-range-overlay .dx-overlay-content .dx-texteditor.dx-state-focused::after,
.dx-datagrid-filter-row .dx-menu-item.dx-state-focused::after,
.dx-datagrid-focus-overlay,
.dx-treelist-focus-overlay {
  border: 2px solid rgb(10 182 85);
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
  background-color: #28c76f;
}

/* 그리드 새로운 로우 추가시 체크 박스 */
.dx-checkbox-indeterminate .dx-checkbox-icon {
  color: #28c76f;
}

.dx-checkbox-indeterminate .dx-checkbox-icon::before {
  background-color: #28c76f;
}

/* 컬럼 체크박스 */
.dx-checkbox-checked .dx-checkbox-icon {
  color: #28c76f;
}

.dx-checkbox.dx-state-hover .dx-checkbox-icon {
  border: 1px solid rgb(10 182 85 / 40%);
}

.dx-checkbox.dx-state-focused .dx-checkbox-icon {
  border: 1px solid rgb(10 182 85);
}

.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
  background-color: #f2f2f2;
}

/* -------------------- devExtreme 설정 (기본설정 오버라이딩) -------------------- */
.dx-button-mode-contained.dx-button-default {
  background-color: #28c76f;
  border-color: transparent;
  color: #fff;
}

.dx-button-mode-contained.dx-button-default.dx-state-hover {
  background-color: #0ab655;
}

.dx-button-mode-contained.dx-button-default.dx-state-focused {
  background-color: #06af52;
}

.dx-button-mode-contained.dx-button-default.dx-state-active {
  background-color: #009d47;
}

.dx-button-mode-outlined.dx-button-default {
  color: #28c76f;
  border-color: #28c76f;
}

.dx-button-mode-outlined.dx-button-default.dx-state-hover {
  background-color: rgb(40 211 125 / 10%);
  border-color: rgb(10 182 85 / 40%);
}

.dx-button-mode-outlined.dx-button-default.dx-state-focused {
  background-color: rgb(40 199 111 / 20%);
  border-color: #06af52;
}

.dx-button-mode-outlined.dx-button-default.dx-state-active {
  background-color: rgb(40 199 111 / 40%);
  color: #28c76f;
  border-color: #009d47;
}

.dx-theme-accent-as-text-color {
  color: #28c76f !important;
}

.dx-theme-accent-as-background-color {
  background-color: #28c76f !important;
  fill: #28c76f !important;
}

.dx-theme-accent-as-border-color {
  border-color: #28c76f !important;
}

.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-default,
.dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-default .dx-icon,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-default,
.dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-default .dx-icon {
  color: #28c76f;
}

.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
  background-color: #28c76f;
}

.dx-texteditor.dx-state-hover,
.dx-radiobutton.dx-state-hover {
  border-color: rgb(10 182 85 / 40%);
}

.dx-radiobutton-icon::before {
  border-color: rgb(10 182 85 / 40%);
}

.dx-radiobutton-checked > *,
.dx-texteditor.dx-state-focused.dx-editor-outlined,
.dx-texteditor.dx-state-active.dx-editor-outlined,
.dx-radiobutton.dx-state-focused.dx-editor-outlined,
.dx-radiobutton.dx-state-active.dx-editor-outlined,
.dx-radiobutton-checked.dx-state-focused.dx-editor-outlined {
  border-color: rgb(10 182 85);
}

.dx-radiogroup.dx-state-readonly .dx-radiobutton-icon::before {
  background-color: #f2f2f2;
}

.dx-item.dx-radiobutton.dx-item-selected.dx-radiobutton-checked,
.dx-radiobutton.dx-state-focused:not(.dx-state-active) .dx-radiobutton-icon::before,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-focused .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-focused .dx-radiobutton-icon::before,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-focused .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-focused .dx-radiobutton-icon::before {
  border-color: #28c76f;
}

.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-hover .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-item.dx-state-hover .dx-radiobutton-icon::before,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-hover .dx-checkbox-icon,
.dx-list.dx-list-select-decorator-enabled .dx-list-select-all.dx-state-hover .dx-radiobutton-icon::before {
  border-color: rgb(10 182 85 / 40%);
}

.dx-state-focused.dx-accordion-item {
  border-color: #28c76f;
}

.dx-texteditor.dx-state-active.dx-editor-filled,
.dx-texteditor.dx-state-active.dx-editor-outlined,
.dx-texteditor.dx-state-active.dx-editor-underlined,
.dx-texteditor.dx-state-focused.dx-editor-filled,
.dx-texteditor.dx-state-focused.dx-editor-outlined,
.dx-texteditor.dx-state-focused.dx-editor-underlined {
  border-color: #28c76f;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused.dx-list-item-selected {
  background-color: rgb(40 199 111 / 70%);
  color: #fff;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
  background-color: #28c76f;
  color: #fff;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active .dx-list-slide-item-content {
  background-color: #28c76f;
  color: #fff;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
  background-color: #28c76f;
  color: #fff;
}

.dx-texteditor.dx-state-readonly {
  background-color: #f2f2f2;
}
