@import "dropdown-tree-extand.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue",
    "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
}

.card {
  border-radius: 0.75rem;
  border-width: 1px;
  border-color: rgb(242 242 242);
  padding: 1.5rem;
}

.card.grid {
  padding: 1.5rem 0;
  overflow-x: scroll;
}

/* ----- 상단 툴바 영역 ----- */
.card-header {
  text-align: right;
}

.card.grid .card-header {
  padding: 0 1.5rem;
}

.card.grid .dx-toolbar-before {
  padding-left: 1.5rem;
}

.card.grid .dx-toolbar-after {
  padding-right: 1.5rem;
}

.card-header .content-header-button-apply,
.card-header .content-header-button-cancel {
  margin-left: 0.375rem;
  height: 34px;
}

/* ----- 하단 메인 컨텐츠 영역 ----- */
.card-body {
  display: flex;
  flex-direction: column;
}

.card-body .content-fieldset {
  margin-bottom: 4rem;
}

/* 하위 타이틀 영역 */
.card-body .content-fieldset-title {
  margin: 1rem 1.75rem;
  font-size: 1.125rem;
  font-weight: 600;
}

.card-body .content-fieldset-title.full {
  margin: 1rem 0 1rem 1.75rem;
  font-size: 1.125rem;
  font-weight: 600;
}

.card-body .content-fieldset-title-desc {
  margin: -0.25rem 1.75rem 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: rgb(76 76 76);
}

/* 필드 영역 */
.card-body .content-field {
  display: grid;
  gap: 0.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom-width: 1px;
  border-color: rgb(242 242 242);
}

.card-body .content-field.address {
  display: grid;
  gap: 0.25rem;
  padding-top: 0.75rem;
}

.card-body .content-field-child {
  padding-top: 0.375rem;
}

/* 필드 좌측 레이블 */
.card-body .content-field-label {
  text-align: left;
}

.card-body .content-field-label.popup {
  grid-column: span 4 / span 4;
  align-self: center;
}

.card-body .content-field-label .content-label-name {
  font-size: 0.875rem;
}

.card-body .content-field-child-label {
  padding: 0.375rem 1rem 0.375rem 0;
  text-align: right;
}

.card-body .content-field-child-label .content-label-name {
  font-size: 0.875rem;
}

.card-body .content-field-label .content-label-name.required::before {
  content: "*";
  margin-right: 0.25rem;
  color: rgb(239 68 68);
}

.card-body .content-field-label .content-label-desc {
  display: block;
  text-align: right;
  padding: 2px 0 0;
  font-size: 0.8rem;
  font-weight: 400;
  color: #666;
}

/* 필드 우측 컴포넌트 */
.card-body .content-field-value {
  grid-column: span 7 / span 7;
}

.card-body .content-field-value.expanded {
  grid-column: span 9 / span 9;
}

.card-body .content-field-value.full {
  grid-column: span 10 / span 10;
}

.card-body .content-field-value.popup {
  grid-column: span 8 / span 8;
}

.card-body .content-field-child-value {
  width: 85%;
  padding-top: 2px;
}

.card-body .content-value-desc {
  display: block;
  padding: 2px 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  color: #666;
}

/* navBar */
.form-switch .form-check-input:checked {
  background-color: #28c76f;
  border-color: #28c76f;
}

@media (min-width: 480px) {
  .card-body .content-field {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 1rem;
  }

  .card-body .content-field-label {
    grid-column: span 4 / span 4;
    text-align: right;
  }

  .card-body .content-field-label.popup {
    text-align: right;
    grid-column: span 4 / span 4;
    align-self: center;
  }

  .card-body .content-field-value {
    grid-column: span 8 / span 8;
  }

  .card-body .content-field-value.expanded {
    grid-column: span 8 / span 8;
  }

  .card-body .content-field-value.full {
    grid-column: span 8 / span 8;
  }
}

@media (min-width: 768px) {
  .card-body .content-field-label {
    grid-column: span 3 / span 3;
  }

  .card-body .content-field-value {
    grid-column: span 9 / span 9;
  }

  .card-body .content-field-value.expanded {
    grid-column: span 9 / span 9;
  }

  .card-body .content-field-value.full {
    grid-column: span 9 / span 9;
  }
}

@media (min-width: 976px) {
  .card-body .content-field-label {
    grid-column: span 2 / span 2;
  }

  .card-body .content-field-value {
    grid-column: span 7 / span 7;
  }

  .card-body .content-field-value.expanded {
    grid-column: span 9 / span 9;
  }

  .card-body .content-field-value.full {
    grid-column: span 10 / span 10;
  }
}
